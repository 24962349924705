import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import expireReducer from "redux-persist-expire";
import storage from "redux-persist/lib/storage";
import { REMOVE_CURRENT_USER } from "store/constants/authenticateConstant";

import AlertReducer from "./AlertReducer";
import AuthenticateReducer from "./AuthenticateReducer";

import EntityReducer from "./EntityReducer";
import FlightRecordReducer from "./FlightRecordReducer";
import FormReducer from "./FormReducer";
import GlobalReducer from "./GlobalReducer";
import QuoteReducer from "./QuoteReducer";
import ResourceReducer from "./ResourceReducer";
import ServicesReducer from "./ServiceReducer";
import SlotReducer from "./SlotReducer";
import SuggestionReducer from "./SuggestionReducer";
import UiReducer from "./UiReducer";
import UserReducer from "./UserReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authenticate"], // only auth will be persisted
  transforms: [
    // Create a transformer by passing the reducer key and configuration. Values
    // shown below are the available configurations with default values
    expireReducer("authenticate", {
      // (Optional) Key to be used for the time relative to which store is to be expired
      persistedAtKey: "loggedInAt",
      // (Required) Seconds after which store will be expired
      expireSeconds: 60 * 60 * 24 * 7 * 2,
      // (Optional) State to be used for resetting e.g. provide initial reducer state
      // expiredState: {},
      // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey`
      // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
      autoExpire: false,
    }),
  ],
};

const appReducer = combineReducers({
  user: UserReducer,
  alert: AlertReducer,
  service: ServicesReducer,
  global: GlobalReducer,
  form: FormReducer,
  resources: ResourceReducer,
  suggestion: SuggestionReducer,
  entity: EntityReducer,
  flightRecord: FlightRecordReducer,
  slot: SlotReducer,
  ui: UiReducer,
  authenticate: AuthenticateReducer,
  quote: QuoteReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === REMOVE_CURRENT_USER) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
