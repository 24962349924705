import { LOAD_INITIAL } from "store/constants/globalConstant";

const initialState = {
  packageTypes: {},
  airports: {},
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL:
      return {
        ...state,
        categories: action.payload.categories?.catgories ?? [],
      };

    default:
      return state;
  }
};
