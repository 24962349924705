import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const Authenticated = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector((state) => state.authenticate.login);
  const isSystemAdmin = useSelector(
    (state) => state.authenticate.isSystemAdmin
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn && isSystemAdmin) {
          return (
            <Redirect
              to={{
                pathname: "/system/quote-contract",
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default Authenticated;
